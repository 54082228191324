import { useTranslation } from "react-i18next";
import React from "react";
import Heading from "../../layout/Heading";
import { useCreateSpecialAnnouncementContext } from "@context/CreateSpecialAnnouncementContext";
import ConfirmationDetail from "@components/shared/ConfirmationDetail";
import ConfirmModal from "@components/shared/ConfirmModal";
import { useCreateSpecialAnnouncement } from "@hooks/mutation/useCreateSpecialAnnouncement";
import { toastError, toastSuccess } from "@utils/toast";
import { isValidationError } from "@utils/formError";

const SpecialAnnouncementLedger: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const {
    stepHelpers,
    info,
    navigateForCancel,
    details,
    setWorkflowInstance,
    setWorkflowCompleted,
    setNetworkErrors,
  } = useCreateSpecialAnnouncementContext();
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);

  const { mutateAsync: createSpecialAnnouncementMutation, isLoading } =
    useCreateSpecialAnnouncement();

  const handleOnConfirm = async () => {
    const data = {
      level0ResourceId: details.level0wrs.id,
      accountingPeriodId: details.accountingPeriod?.id,
      startAt: details.startAt ? new Date(details.startAt) : new Date(),
      subscriberIds: details.subscribers.map((i: any) => i.id),
    };
    try {
      let specialAnnouncementIds = [];
      let instance: any;

      const [res, workflowInstance] =
        await createSpecialAnnouncementMutation(data);
      for (const sa of res) {
        specialAnnouncementIds.push(sa.id);
      }

      instance = workflowInstance;

      setWorkflowInstance(instance);

      setWorkflowCompleted(true);
      toastSuccess(t("special_announcement.save_succeed"));
      setNetworkErrors([]);
      stepHelpers.goToNextStep();
    } catch (error: any) {
      const { data } = error?.response;
      const errorMessages = isValidationError(error)
        ? data?.errors?.map((i: any) => i.message)
        : [data?.message];

      toastError(
        <>
          <p>
            {t("special_announcement.save_failed", {
              error: error?.response?.data?.message || error?.message,
            })}
          </p>
          {errorMessages.length && (
            <ul className="list-disc pl-4">
              {errorMessages.map((text: any) => {
                return <li key={text}>{text}</li>;
              })}
            </ul>
          )}
        </>,
      );
      setNetworkErrors(errorMessages);
    }

    setShowConfirmModal(false);
  };

  return (
    <>
      <div className="flex flex-col gap-6 p-6 grow">
        <Heading light={true}>
          {t("special_announcement.confirm_values")}
        </Heading>
        <ConfirmationDetail
          data={[info.wrsHierarchies, info.subscribers, info.details]}
          onEdit={index => stepHelpers.setStep(index)}
        />
      </div>
      <footer className="flex gap-4 p-6  border-t border-gray-200">
        <button
          type="button"
          className="btn-outline-primary"
          onClick={stepHelpers.goToPrevStep}
        >
          {t("common.prev_step")}
        </button>
        <button
          type="button"
          className="btn-primary"
          onClick={() => setShowConfirmModal(true)}
        >
          {t("common.ledger")}
        </button>
        <button
          type="button"
          className="btn-outline-primary"
          onClick={navigateForCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>

      <ConfirmModal
        open={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={handleOnConfirm}
        isSubmitting={isLoading}
        confirmText={t("common.ledger")}
      >
        {t("special_announcement.modal_confirmation")}
      </ConfirmModal>
    </>
  );
};

export default SpecialAnnouncementLedger;
