import api from "./api";

export const getExtractionRight = async (id: string): Promise<any> => {
  const { data } = await api.get(`admin/extractionRights/${id}`);
  return data.extractionRight;
};

export type GetAllExtractionRightsParams = Partial<{
  name: string;
  level0ResourceId: string;
  level1ResourceId: string;
  subscriberIds: string[];
  assertedByWalletId: string;
  waterClassId: string;
  types: number[];
  preload: string[];
  isActive: boolean;
  isDeleted: boolean;
  withDeleted: boolean;
}>;

export const getAllExtractionRights = async (
  params: GetAllExtractionRightsParams = {},
) => {
  const { data } = await api.get("admin/extractionRights", { params });

  return data.extractionRights;
};

export const getExtractionRightsByLevel1WRS = async (
  level1ResourceId: string,
  showDeleted?: boolean,
): Promise<any> => {
  const { data } = await api.get("/admin/extractionRights", {
    params: {
      level1ResourceId,
      deleted: showDeleted ? 1 : undefined,
    },
  });

  return data.extractionRights;
};

export type CreateExtractionRightPayload = {
  name: string;
  subscriberId: string;
  waterClassId: string;
  level0ResourceId: string;
  description: string;
  reference: string;
  volume: number;
  startAt?: Date;
  endAt?: Date;
  itemNo?: string;
  isActive: boolean;
  isAmalgamationOrSubdivision: boolean;
};

export const createExtractionRight = async (
  extractionRight: CreateExtractionRightPayload,
): Promise<any> => {
  const { data } = await api.post(`/admin/extractionRights`, extractionRight);

  return [data.extractionRight, data.workflowInstance];
};

export const updateExtractionRight = async ({
  extractionRightId,
  ...payload
}: any) => {
  const { data } = await api.put(
    `/admin/extractionRights/${extractionRightId}`,
    payload,
  );

  return [data.extractionRight, data.workflowInstance];
};

export type LinkExtractionPointPayload = {
  extractionRightId: string;
  extractionPointIds: string[];
};

export const linkExtractionPoint = async (
  payload: LinkExtractionPointPayload,
) => {
  const { data } = await api.post(
    "/admin/extractionRights/linkExtractionPoints",
    payload,
  );

  return [data.extractionRight, data.workflowInstance];
};

export const checkName = async (payload: { name: string }): Promise<any> => {
  const { data } = await api.post("/admin/extractionRights/checkName", payload);

  return data.exists;
};

export const extractionRightTypes = {
  WA: 1,
  WSA: 2,
  DL: 3,
  FD: 4,
  SD: 5,
  PT: 6,
  Q: 7,
  WH: 8,
  SA: 9,
} as const;

export const getExtractionRightTypeIdentifier = (
  value: (typeof extractionRightTypes)[keyof typeof extractionRightTypes],
): keyof typeof extractionRightTypes => {
  const key = Object.keys(extractionRightTypes).find(
    key =>
      extractionRightTypes[key as keyof typeof extractionRightTypes] === value,
  );
  return key as keyof typeof extractionRightTypes;
};

export const activateExtractionRight = async ({
  extractionRightId,
  ...payload
}: {
  extractionRightId: string;
  active: boolean;
  note: string;
}) => {
  const { data } = await api.put(
    `/admin/extractionRights/${extractionRightId}/activate`,
    payload,
  );

  return [data.extractionRight, data.workflowInstance];
};

export const deleteExtractionRight = async ({
  extractionRightId,
  ...payload
}: {
  extractionRightId: string;
  note: string;
}) => {
  const { data } = await api.delete(
    `/admin/extractionRights/${extractionRightId}`,
    { data: payload },
  );

  return [data.extractionRight, data.workflowInstance];
};
