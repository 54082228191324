import { useTranslation } from "react-i18next";

import Modal from "@components/layout/Modal";
import Tag from "@components/shared/Tag";
import { formatDateTime } from "@utils/formatDateTime";
import _ from "lodash";
import { formatAccountingPeriod } from "@utils/formatAccountingPeriod";
import { useState } from "react";

type Props = {
  data?: any;
  onClose: () => void;
};
const SpecialAnnouncementModal: React.FunctionComponent<Props> = ({
  data,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const [showAll, setShowAll] = useState(false);

  const hasEnded = data?.hasEnded;

  return (
    <>
      <Modal open={data}>
        <div className="flex flex-col">
          <div className="border-b p-4">
            <h3 className="text-lg font-bold leading-6 text-gray-900 capitalize">
              {t("notification.type_special_announcement")}
            </h3>
          </div>
          <div className="p-4 max-h-96 overflow-auto">
            <dl className="grid grid-cols-2 gap-x-4 gap-y-2 sm:grid-cols-1 mb-4">
              <div className="col-span-2 sm:col-span-1 flex gap-1">
                <dt className="font-medium text-gray-900">
                  {t("common.level1wrs")}:
                </dt>
                <dd className="text-gray-500">
                  {data?.level0Resource?.parent?.identifier}
                </dd>
              </div>
              <div className="col-span-2 sm:col-span-1 flex gap-1">
                <dt className="font-medium text-gray-900">
                  {t("common.accounting_period")}:
                </dt>
                <dd className="text-gray-500">
                  {formatAccountingPeriod(data?.accountingPeriod)}
                </dd>
              </div>
              <div className="col-span-2 sm:col-span-1 flex gap-1">
                <dt className="font-medium text-gray-900">
                  {t("common.subscribers")}:
                </dt>
                <dd>
                  <ol className="text-gray-500 px-4">
                    {data?.subscribers
                      ?.slice(0, showAll ? data?.subscribers.length : 5)
                      .map((r: any, index: any) => {
                        return (
                          <li key={index} className="mb-1 font-medium">
                            {index + 1}. {r?.name}
                          </li>
                        );
                      })}
                    {data?.subscribers?.length > 5 && (
                      <ol className="mt-1 text-sm text-blue-900">
                        <button onClick={() => setShowAll(!showAll)}>
                          {showAll
                            ? t("common.see_less")
                            : t("common.see_more")}
                        </button>
                      </ol>
                    )}
                  </ol>
                </dd>
              </div>{" "}
              <div className="col-span-2 sm:col-span-1 flex gap-1">
                <dt className="font-medium text-gray-900">
                  {t("common.level0wrs")}:
                </dt>
                <dd className="text-gray-500">
                  {data?.level0Resource?.identifier}
                </dd>
              </div>
              <div className="col-span-2 sm:col-span-1 flex gap-1">
                <dt className="font-medium text-gray-900">
                  {t("notification.started_at")}:
                </dt>
                <dd className="text-gray-500">
                  {formatDateTime(new Date(data?.startAt))}
                </dd>
              </div>
              {data?.endAt && (
                <div className="col-span-2 sm:col-span-1 flex gap-1">
                  <dt className="font-medium text-gray-900">
                    {t("notification.end_at")}:
                  </dt>
                  <dd className="text-gray-500">
                    {data?.endAt ? formatDateTime(new Date(data?.endAt)) : "-"}
                  </dd>
                </div>
              )}
              {hasEnded && (
                <>
                  <div className="col-span-2 sm:col-span-1 flex gap-1">
                    <dt className="font-medium text-gray-900">
                      {t("notification.actual_end_at")}:
                    </dt>
                    <dd className="text-gray-500">
                      {data?.allocationTriggeredAt
                        ? formatDateTime(new Date(data?.allocationTriggeredAt))
                        : data?.endAt
                          ? formatDateTime(new Date(data?.endAt))
                          : "-"}
                    </dd>
                  </div>
                  <div className="col-span-2 sm:col-span-1 flex gap-1">
                    <dt className="font-medium text-gray-900">
                      {t("notification.end_reason")}:
                    </dt>
                    <dd className="text-gray-500">
                      {_.lt(new Date(data?.endAt), new Date()) && data?.endAt
                        ? t("notification.end_reason_1")
                        : "-"}
                    </dd>
                  </div>
                </>
              )}
              <div className="col-span-2 sm:col-span-1 flex gap-1">
                <dt className="font-medium text-gray-900">
                  {t("common.status")}:
                </dt>
                <dd className="text-gray-500">
                  <Tag status={hasEnded ? "info" : "success"}>
                    {hasEnded ? t("common.ended") : t("common.active")}
                  </Tag>
                </dd>
              </div>
            </dl>
          </div>

          <div className="mt-5 border-t p-4 flex flex-row-reverse gap-3 text-sm">
            <button onClick={onClose} className="btn-primary">
              {t("common.cancel")}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SpecialAnnouncementModal;
