import { Trans, useTranslation } from "react-i18next";

import WorkflowPanels from "@components/form/WorkflowPanels";
import InfoPanel from "@components/form/InfoPanel";
import StepButton from "@components/shared/StepButton";
import { useCreateSpecialAnnouncementContext } from "@context/CreateSpecialAnnouncementContext";
import StepInfo from "@components/shared/StepInfo";
import SpecialAnnouncementSelectWRS from "../special_announcement/SpecialAnnouncementSelectWRS";
import { useState } from "react";
import CreateAccountingPeriodModal from "@components/modal/CreateAccountingPeriodModal";
import WorkflowComplete from "@components/shared/WorkflowComplete";
import SpecialAnnouncementDetails from "./SpecialAnnouncementDetails";
import SpecialAnnouncementLedger from "./SpecialAnnouncementLedger";
import SpecialAnnouncementSelectSubscribers from "./SpecialAnnouncementSelectSubscribers";

const CreateSpecialAnnouncementWorkflow = () => {
  const { t } = useTranslation();
  const {
    currentStep,
    networkErrors,
    info,
    details,
    workflowInstance,
    workflowCompleted,
  } = useCreateSpecialAnnouncementContext();

  const [openCreateAccountingPeriodModal, setOpenCreateAccountingPeriodModal] =
    useState(false);

  let index = 0;

  const steps = [
    {
      label: (
        <StepButton
          index={index++}
          currentStep={currentStep}
          info={<StepInfo data={info?.wrsHierarchies.body} />}
          isOnChain={workflowCompleted}
        >
          {t("special_announcement.select_hierarchy")}
        </StepButton>
      ),
      panel: <SpecialAnnouncementSelectWRS />,
      infoPanel: (
        <InfoPanel
          warnings={[
            ...(Boolean(details.level1wrs?.id)
              ? [
                  t("special_announcement.new_accounting_period", {
                    level1ResourceIdentifier: details.level1wrs?.identifier,
                  }),
                ]
              : []),
          ]}
          actions={[
            ...(Boolean(details.level1wrs?.id)
              ? [
                  {
                    action: () => {
                      setOpenCreateAccountingPeriodModal(true);
                    },
                    label: t("accounting_period.create.modal.title"),
                  },
                ]
              : []),
          ]}
        >
          <div className="space-y-4">
            <p>{t("special_announcement.info.step_1.intro")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={index++}
          currentStep={currentStep}
          info={<StepInfo data={info?.subscribers.body} />}
          isOnChain={workflowCompleted}
        >
          {t("special_announcement.select_subscribers")}
        </StepButton>
      ),
      panel: <SpecialAnnouncementSelectSubscribers />,
      infoPanel: (
        <InfoPanel
          warnings={[
            ...(!Boolean(details.subscribers?.length)
              ? [t("special_announcement.info.step_2.warning")]
              : []),
          ]}
          actions={[
            {
              label: t("subscriber.add_subscriber"),
              action: () => {
                window.open(
                  `/polestar/subscribers/create?newWindow=true`,
                  "_blank",
                );
              },
            },
          ]}
        >
          <div className="space-y-4">
            <p>{t("special_announcement.info.step_2.intro")}</p>
            <p className="pl-2">
              <Trans i18nKey="special_announcement.info.step_2.body_1" />
            </p>
            <p className="pl-2">
              <Trans i18nKey="special_announcement.info.step_2.body_2" />
            </p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={index++}
          currentStep={currentStep}
          info={<StepInfo data={info?.details.body} />}
          isOnChain={workflowCompleted}
        >
          {t("special_announcement.enter_values")}
        </StepButton>
      ),
      panel: <SpecialAnnouncementDetails />,
      infoPanel: (
        <InfoPanel>
          <div className="space-y-4">
            <p>{t("special_announcement.info.step_3.intro")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={index++}
          currentStep={currentStep}
          isOnChain={workflowCompleted}
        >
          {t("special_announcement.confirm_values")}
        </StepButton>
      ),
      panel: <SpecialAnnouncementLedger />,
      infoPanel: (
        <InfoPanel
          successes={
            networkErrors.length > 0
              ? []
              : [t("special_announcement.info.step_4.success_1")]
          }
          errors={networkErrors}
        >
          <div className="space-y-4">
            <p>{t("information.edit_info_intro")}</p>
            <p className="pl-2">
              <Trans i18nKey="special_announcement.info.step_4.intro_1" />
            </p>
            <p className="pl-2">
              <Trans i18nKey="special_announcement.info.step_4.intro_2" />
            </p>
            <p className="pl-2">
              <Trans i18nKey="special_announcement.info.step_4.intro_3" />
            </p>
            <p>{t("information.edit_conclusion")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={index++}
          currentStep={currentStep}
          isOnChain={currentStep === 4}
        >
          {t("special_announcement.workflow_completed")}
        </StepButton>
      ),
      panel: (
        <div className="flex flex-col grow h-[800px] max-h-screen">
          <WorkflowComplete references={[workflowInstance?.id]} />
        </div>
      ),
      infoPanel: (
        <InfoPanel
          successes={
            networkErrors.length > 0
              ? []
              : [t("special_announcement.info.complete")]
          }
          errors={networkErrors}
        >
          <div className="space-y-4"></div>
        </InfoPanel>
      ),
    },
  ];

  return (
    <>
      <WorkflowPanels selectedStep={currentStep} steps={steps} />

      {Boolean(details.level1wrs?.id) && (
        <CreateAccountingPeriodModal
          open={openCreateAccountingPeriodModal}
          level1Resource={{
            id: details.level1wrs?.id,
            name: details.level1wrs?.name,
          }}
          onClose={() => {
            setOpenCreateAccountingPeriodModal(false);
          }}
        />
      )}
    </>
  );
};

export default CreateSpecialAnnouncementWorkflow;
