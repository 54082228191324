import React, { useState } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import TableMultiSelection from "@components/layout/TableMultiSelection";
import SearchInput from "@components/form/SearchInput";
import Label from "@components/form/Label";
import { SpecialClassCategory } from "@services/classCategory";
import { useAllSubscribers } from "@hooks/query/useAllSubscribers";
import { useAllWaterClasses } from "@hooks/query/useAllWaterClasses";
import { useCreateSpecialAnnouncementContext } from "@context/CreateSpecialAnnouncementContext";
import { Link } from "react-router-dom";
import ExternalLinkIcon from "@components/icons/ExternalLinkIcon";

const SpecialAnnouncementSelectSubscribers: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { details, stepHelpers, navigateForCancel, handleChangeDetails } =
    useCreateSpecialAnnouncementContext();
  const [selectedWaterClass, setSelectedWaterClass] = useState("");
  const [searchedValue, setSearchedValue] = useState("");

  const { data: waterClasses } = useAllWaterClasses({
    params: {
      level0ResourceId: details.level0wrs?.id,
    },
    enabled: Boolean(details.level0wrs?.id),
    select: (data: any[]) => {
      const ignoredClassCategories = Object.values(SpecialClassCategory);
      return data?.filter(
        i => !ignoredClassCategories.includes(i.classCategory?.name),
      );
    },
  });

  const { data: subscribers } = useAllSubscribers({
    params: {
      level1ResourceId: details.level1wrs?.id,
    },
    options: {
      enabled: Boolean(details.level1wrs?.id),
      select: (data: any[]) => {
        const filteredSubscribers = data
          .map((subscriber: any) => {
            const filteredExtractionRights = subscriber.extractionRights.filter(
              (i: any) => details.level0wrs.id === i.level0ResourceId,
            );

            return {
              ...subscriber,
              extractionRights: filteredExtractionRights,
            };
          })
          .filter((subscriber: any) => subscriber.extractionRights.length > 0);

        return filteredSubscribers;
      },
    },
  });

  const getWaterClassOptions = (waterClasses: any[]) => {
    return waterClasses
      ?.map((waterClass: any) => ({
        label: waterClass.name,
        value: waterClass.name,
      }))
      ?.filter(
        (waterClass: any, i: number, self: any[]) =>
          self.findIndex((w: any) => w.label === waterClass.label) === i,
      )
      ?.sort((a: any, b: any) => a.label.localeCompare(b.label));
  };

  const handleSave = (e: any) => {
    e.preventDefault();
    stepHelpers.goToNextStep();
  };

  return (
    <>
      <div className="p-6 pb-0 flex flex-col grow">
        <Heading className="text-xl leading-6 mb-6" light={true}>
          {t("notification.select_subscribers")}
        </Heading>
        <header className="grid grid-cols-1 gap-2 md:grid-cols-4 md:gap-3 mb-4">
          <div>
            <Label htmlFor="subscriber">{t("subscriber.filter_name_id")}</Label>
            <SearchInput
              id="subscriber"
              onChange={e => {
                setSearchedValue(e.target.value);
              }}
            />
          </div>
          <div>
            <Label htmlFor="waterClass">{t("common.water_class")}</Label>
            <Select
              inputId="waterClass"
              options={getWaterClassOptions(waterClasses)}
              value={getWaterClassOptions(waterClasses)?.find(
                s => s.value === selectedWaterClass,
              )}
              onChange={e => {
                setSelectedWaterClass(e?.value);
              }}
              openMenuOnFocus
              isClearable
            />
          </div>
        </header>
        <TableMultiSelection
          containerClassName="rounded-none md:rounded-none"
          fields={[
            {
              title: t("subscriber.name"),
              name: "name",
            },
            {
              title: t("subscriber.account_number"),
              name: "accountNumber",
            },
            {
              title: t("common.entitlement_zones"),
              name: "zones",
            },
            {
              title: t("common.water_classes"),
              name: "waterClasses",
            },
            {
              title: "",
              name: "view",
            },
          ]}
          data={subscribers
            ?.filter(
              (row: { accountNumber: string; name: string }) =>
                !searchedValue.length ||
                row.name
                  .toString()
                  .toLowerCase()
                  .includes(searchedValue.toString().toLowerCase()) ||
                row.accountNumber.toString().includes(searchedValue.toString()),
            )
            ?.map((subscriber: any) => ({
              ...subscriber,
              zones: subscriber.extractionRights
                ?.map((right: any) => right?.level0Resource.identifier)
                ?.filter(
                  (value: any, index: any, self: string | any[]) =>
                    self.indexOf(value) === index,
                )
                .join(", "),
              view: (
                <Link
                  to={`/polestar/subscribers/${subscriber.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn-default flex items-center gap-3 w-min"
                >
                  <ExternalLinkIcon className="w-4 h-4" />
                  {t("common.view")}
                </Link>
              ),
              waterClasses: subscriber.extractionRights
                ?.map((right: any) => right?.waterClass.name)
                ?.filter(
                  (value: any, index: any, self: string | any[]) =>
                    self.indexOf(value) === index,
                )
                .join(", "),
            }))
            ?.filter(
              (row: { waterClasses: string }) =>
                !selectedWaterClass ||
                row.waterClasses.split(", ").includes(selectedWaterClass),
            )}
          selectionKey="id"
          selectedKeys={details.subscribers.map(subscriber => subscriber.id)}
          onSelectionChange={data => {
            const selectedSubscribers = subscribers?.filter(
              (subscriber: { id: string; accountNumber: string }) =>
                data.includes(subscriber.id),
            );
            handleChangeDetails("subscribers", selectedSubscribers);
          }}
          pageSize={50}
          stickyHeader
        />
      </div>

      <footer className="flex gap-4 p-6 border-t border-gray-200">
        <button
          type="button"
          className="btn-outline-primary"
          onClick={stepHelpers.goToPrevStep}
        >
          {t("common.prev_step")}
        </button>

        <button
          className="btn-primary"
          disabled={!details.subscribers.length}
          onClick={handleSave}
        >
          {t("common.next_step")}
        </button>
        <button
          type="button"
          className="btn-outline-primary text-sm font-semibold"
          onClick={navigateForCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </>
  );
};

export default SpecialAnnouncementSelectSubscribers;
