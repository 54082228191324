import api from "./api";

export const getAllSpecialAnnouncements = async (params?: any) => {
  const { data } = await api.get("/admin/specialAnnouncements", { params });
  return data.specialAnnouncements ?? [];
};

export type CreateSpecialAnnouncementPayload = {
  startAt: Date;
  level0ResourceId: string;
  accountingPeriodId: string;
  subscriberIds: string[];
};

export const createSpecialAnnouncement = async (
  payload: CreateSpecialAnnouncementPayload,
): Promise<any> => {
  const { data } = await api.post("/admin/specialAnnouncements", payload);

  return [data.specialAnnouncements, data.workflowInstance];
};

export type EndSpecialAnnouncementPayload = {
  endAt?: Date;
  specialAnnouncementIds: Array<string>;
};
