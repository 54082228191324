import React from "react";
import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import ConfirmModal from "@components/shared/ConfirmModal";
import { useCreateOrUpdateExtractionRightContext } from "@context/CreateOrUpdateExtractionRightContext";
import ConfirmationDetail from "@components/shared/ConfirmationDetail";
import { useCreateExtractionRight } from "@hooks/mutation/useCreateExtractionRight";
import { toastError, toastSuccess } from "@utils/toast";
import { isValidationError } from "@utils/formError";
import { useEvidenceContext } from "@context/shared/EvidenceContext";
import { useUpdateExtractionRight } from "@hooks/mutation/useUpdateExtractionRight";
import { convertMLToLiter } from "@utils/convertUnits";
import { useUpdateAmalgamationOrSubdivisionRequest } from "@hooks/mutation/useUpdateAmalgamationOrSubdivisionRequest";
import { DBTables } from "@utils/constants/dbTables";
import { useLinkExtractionPoint } from "@hooks/mutation/useLinkExtractionPointWithRights";

const ExtractionRightLedger = () => {
  const { t } = useTranslation();
  const {
    stepHelpers,
    setWorkflowCompleted,
    setWorkflowInstance,
    details,
    navigateForCancel,
    setNetworkErrors,
    handleChangeDetails,
    setEventRelatedModalIds,
    updatingRight,
    amalgamateOrSubdivideDetails,
    info,
  } = useCreateOrUpdateExtractionRightContext();
  const {
    reset,
    uploadEvidences,
    isSubmitting,
    archiveUnselectedEvidences,
    isArchivingUnselectedEvidence,
    getEvidencesInfo,
  } = useEvidenceContext();
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const { mutateAsync: createExtractionRightMutation, isLoading } =
    useCreateExtractionRight();

  const { mutateAsync: updateAmalgamation, isLoading: isUpdatingAmalgamation } =
    useUpdateAmalgamationOrSubdivisionRequest();

  const {
    mutateAsync: linkExtractionPointMutation,
    isLoading: isLoadingLinking,
  } = useLinkExtractionPoint();

  const {
    mutateAsync: updateExtractionRightMutation,
    isLoading: isLoadingExtractionRightUpdate,
  } = useUpdateExtractionRight();

  const handleOnConfirm = async () => {
    const data = {
      subscriberId: details.subscriber?.id,
      waterClassId: details.waterClass?.id,
      level0ResourceId: details.level0wrs?.id,
      name: details.name,
      description: details.description,
      reference: details.reference,
      volume: details.volume ? convertMLToLiter(details.volume) : 0,
      startAt: details.startAt,
      endAt: details.endAt,
      billingGroupId: details.billingGroup?.id,
      isActive: details.isActive,
      isAmalgamationOrSubdivision: !!amalgamateOrSubdivideDetails,
    };
    try {
      let extractionRightId = "";
      let instance: any;

      if (updatingRight) {
        const [res, workflowInstance] = await updateExtractionRightMutation({
          extractionRightId: details.id,
          ...data,
        });

        extractionRightId = res.id;
        instance = workflowInstance;
      } else {
        const [res, workflowInstance] = await createExtractionRightMutation(
          data,
        );

        handleChangeDetails("id", res.id);
        extractionRightId = res.id;
        instance = workflowInstance;
      }

      setWorkflowInstance(instance);

      const linkAndActivateExtractionPoints =
        details?.linkToExtractionPoints.filter((ep: any) => {
          return ep.level0wrsId === details.level0wrs?.id && !ep.isActive;
        });

      if (linkAndActivateExtractionPoints.length > 0) {
        for (const ep of linkAndActivateExtractionPoints) {
          const extractionPointId = ep.id;
          // TODO: Set date appropriately
          await linkExtractionPointMutation({
            extractionPointId,
            extractionRightIds: [extractionRightId],
            effectiveAt: new Date(),
          });
          setEventRelatedModalIds((eventRelatedModalIds: any) => [
            ...eventRelatedModalIds,
            extractionPointId,
          ]);
        }
        toastSuccess(
          t("extraction_right.create.toast.warning_2", { name: details.name }),
        );
      }

      await archiveUnselectedEvidences({
        referenceId: extractionRightId,
        referenceTable: "extraction_rights",
        evidenceIds: details.activatedEvidenceIds,
      });

      await uploadEvidences({
        description: t("extraction_right.create.step_3.description", {
          extractionRightName: details.name,
        }),
        references: [
          {
            referenceId: extractionRightId,
            referenceTable: DBTables.ExtractionRights,
          },
          {
            referenceId: details.subscriber?.id,
            referenceTable: DBTables.Subscribers,
          },
          {
            referenceId: details.level1wrs?.id,
            referenceTable: DBTables.WRSHierarchies,
          },
          {
            referenceId: instance.id,
            referenceTable: DBTables.WorkflowInstances,
          },
        ],
      });

      if (amalgamateOrSubdivideDetails) {
        await updateAmalgamation({
          newExtractionRightIds: [extractionRightId],
          administrativeApprovalId: amalgamateOrSubdivideDetails.id,
        });
      }

      setWorkflowCompleted(true);
      if (updatingRight) {
        toastSuccess(
          t("extraction_right.update.toast.success", { name: details.name }),
        );
      } else {
        toastSuccess(
          t("extraction_right.create.toast.success", { name: details.name }),
        );
      }

      setNetworkErrors([]);
      reset();
    } catch (error: any) {
      let errorMessages = [];
      if (isValidationError(error)) {
        const { errors = [] } = error?.response?.data;
        errorMessages = errors.map((i: any) => i.message);
      } else {
        errorMessages = [error?.message];
      }
      toastError(
        <>
          <p>
            {t("extraction_right.create.toast.failure", {
              error: error?.response?.data?.message || error?.message,
            })}
          </p>
          {errorMessages.length && (
            <ul className="list-disc pl-4">
              {errorMessages.map((text: any) => {
                return <li key={text}>{text}</li>;
              })}
            </ul>
          )}
        </>,
      );
      setNetworkErrors(errorMessages);
    }

    setShowConfirmModal(false);
  };

  return (
    <>
      <div className="flex flex-col gap-6 p-6 grow justify-between">
        <Heading light>
          {t("extraction_right.create.step_5.definition_complete")}
        </Heading>

        <ConfirmationDetail
          data={[
            info.level1Resource,
            info.subscriber,
            info.extractionRight,
            getEvidencesInfo(),
            info.extractionPoints,
          ]}
          onEdit={index =>
            stepHelpers.setStep(updatingRight ? index - 1 : index)
          }
        />
      </div>

      <footer className="flex gap-4 p-6 border-t border-gray-200">
        <button
          type="button"
          className="btn-outline-primary"
          onClick={stepHelpers.goToPrevStep}
        >
          {t("common.prev_step")}
        </button>
        <button
          type="button"
          className="btn-primary"
          onClick={() => setShowConfirmModal(true)}
        >
          {t("common.ledger")}
        </button>
        <button
          type="button"
          className="btn-outline-primary"
          onClick={() => navigateForCancel()}
        >
          {t("common.cancel")}
        </button>
      </footer>

      <ConfirmModal
        open={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={handleOnConfirm}
        isSubmitting={
          isLoading ||
          isLoadingLinking ||
          isSubmitting ||
          isLoadingExtractionRightUpdate ||
          isArchivingUnselectedEvidence ||
          isUpdatingAmalgamation
        }
        confirmText={t("common.ledger")}
      >
        {t("extraction_right.create.modal.confirm_title")}
      </ConfirmModal>
    </>
  );
};

export default ExtractionRightLedger;
