import EditButton from "@components/shared/EditButton";
import OnChain from "./OnChain";
import { ReactNode } from "react";

type Detail = {
  key: string;
  value: ReactNode;
};

export type ConfirmData = {
  title: string;
  body: Detail[];
  onChain?: boolean;
  disableEdit?: boolean;
  hidden?: boolean;
};

type Props = {
  data: ConfirmData[];
  onEdit: (index: number) => void;
};

const ConfirmationDetail: React.FunctionComponent<Props> = ({
  data,
  onEdit,
}) => {
  return (
    <>
      {data
        .filter(item => !item.hidden)
        .map((item, index) => (
          <div key={item.title} className="space-y-1.5">
            <h3 className="text-lg font-bold">{item.title}</h3>
            {item.body.map(({ key, value = "-" }: any) => (
              <div className="text-sm text-gray-600" key={`body--${key}`}>
                {Boolean(key) && <strong>{key}: </strong>}
                {value}
              </div>
            ))}
            {item.onChain ? (
              <OnChain />
            ) : item?.disableEdit ? null : (
              <EditButton onClick={() => onEdit(index)} />
            )}
          </div>
        ))}
    </>
  );
};

export default ConfirmationDetail;
