import React from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { sortBy, startCase, sumBy, uniqBy } from "lodash";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";

import Heading from "@components/layout/Heading";
import Table from "@components/layout/Table";
import Label from "@components/form/Label";
import { usePermanentTransferContext } from "@context/PermanentTransferContext";
import { useStepContext } from "@context/shared/StepContext";
import { useAllExtractionRights } from "@hooks/query/useAllExtractionRights";
import { useAllRightFractions } from "@hooks/query/useAllRightFractions";
import { extractionRightTypes } from "@services/extractionRight";
import { formatDate } from "@utils/formatDate";
import { formatVolume } from "@utils/formatVolume";
import { convertLiterToML } from "@utils/convertUnits";
import { useSubscriberLevel0Resource } from "@hooks/query/useSubscriberLevel0Resource";
import ConfirmModal from "@components/shared/ConfirmModal";

const getRemainingBalance = (
  extractionRightId: string,
  rightFractions: any,
) => {
  const filteredRightFractions = rightFractions.filter(
    (i: any) => i.extractionRightId === extractionRightId,
  );

  return sumBy(filteredRightFractions, (i: any) => +i.balance);
};

const SelectExtractionRights: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { stepHelpers } = useStepContext();
  const [openConfirmModal, setOpenConfirmModal] = React.useState(false);
  const {
    seller,
    level0Resource,
    selectLevel0Resource,
    selectedExtractionRights,
    setSelectedExtractionRights,
    handleCancel,
  } = usePermanentTransferContext();

  const { data: extractionRights = [] } = useAllExtractionRights({
    params: {
      assertedByWalletId: seller?.walletId,
      types: [extractionRightTypes.WA, extractionRightTypes.WSA],
      isActive: true,
    },
    options: {
      enabled: Boolean(seller?.walletId),
    },
  });

  const { data: rightFractions = [] } = useAllRightFractions({
    params: {
      extractionRightIds: extractionRights.map((i: any) => i?.id),
      isLinked: true,
    },
    enabled: extractionRights.length > 0,
  });
  const {
    data: subscriberLevel0Resource = {},
    isLoading: isLevel0ResourceLoading,
  } = useSubscriberLevel0Resource({
    params: {
      subscriberId: seller.id,
      level0ResourceId: level0Resource.id,
    },
  });

  const tableFields = [
    {
      title: t("extraction_right.name"),
      name: "name",
    },
    {
      title: t("extraction_right.volume"),
      name: "formatVolume",
    },
    {
      title: t("extraction_right.h2w_balance"),
      name: "formatRemainingBalance",
    },
    {
      title: "",
      name: "actions",
    },
  ];

  const transformRights = extractionRights.map((right: any) => {
    const remainingBalance = getRemainingBalance(right.id, rightFractions);

    return {
      id: right.id,
      level0Resource: {
        id: right.level0ResourceId,
        name: right.level0Resource?.name,
        identifier: right.level0Resource?.identifier,
      },
      allocationType: right?.type?.identifier,
      name: right?.name,
      source: startCase(right?.level0Resource?.source),
      formatVolume: formatVolume(right?.volume),
      volume: right?.volume,
      waterClassId: right?.waterClass?.id,
      waterClassName: right?.waterClass?.name,
      startAt: right?.startAt ? formatDate(right.startAt) : "-",
      endAt: right?.endAt ? formatDate(right.endAt) : "-",
      cumulativeAllocated: right?.cumulativeAllocated ?? 0,
      itemNo: right?.itemNo ?? "",
      remainingBalance,
      formatRemainingBalance: formatVolume(remainingBalance),
      extractionPoints: [],
      actions: (
        <a
          className="inline-flex gap-2 justify-center btn-default text-sm"
          href={`/polestar/subscribers/${seller.id}`}
          target="_blank"
          rel="noreferrer"
        >
          <ArrowTopRightOnSquareIcon className="w-5 h-5" />
          {t("common.view")}
        </a>
      ),
      billingGroup: {
        id: right.billingGroup.id,
        name: right.billingGroup.name,
      },
    };
  });

  const level0ResourceOptions = uniqBy(
    transformRights,
    "level0Resource.id",
  ).map((i: any) => ({
    value: i.level0Resource?.id,
    label: i.level0Resource?.identifier,
    name: i.level0Resource?.name,
  }));

  const filteredRights = transformRights.filter(
    (i: any) => i.level0Resource?.id === level0Resource.id,
  );
  const tableData = level0Resource?.id ? filteredRights : [];

  return (
    <>
      <div className="p-6 flex flex-col gap-4">
        <Heading light>{t("permanent_trade.select_extraction_right")}</Heading>

        <div className="max-w-xs">
          <Label htmlFor="level0Resource">
            {t("water_class.select_level0_resource")}
          </Label>
          <Select
            inputId="level0Resource"
            options={sortBy(level0ResourceOptions, "label")}
            value={level0ResourceOptions.find(
              (i: any) => i.value === level0Resource.id,
            )}
            onChange={(e: any) => {
              selectLevel0Resource({
                id: e?.value ?? "",
                name: e?.name ?? "",
                identifier: e?.label ?? "",
              });
            }}
            openMenuOnFocus
          />
        </div>

        <Table
          fields={tableFields}
          data={tableData}
          selectionKey="id"
          selectedKeys={selectedExtractionRights.map(i => i.id)}
          onSelectionChange={(ids: string[]) => {
            const selectedRight = tableData
              .filter((i: any) => ids.includes(i.id))
              .map((i: any) => ({
                id: i.id,
                name: i.name,
                volume: +i.volume,
                transferRightVolume: convertLiterToML(+i.volume),
                transferRightPercentage: 100,
                isRightVolumeError: false,
                remainingBalance: i.remainingBalance,
                isTransferH2W: false,
                transferH2W: convertLiterToML(i.remainingBalance),
                transferH2WPercentage: 100,
                isH2WError: false,
                level0Resource: i.level0Resource,
                itemNo: i.itemNo,
                waterClassId: i.waterClassId,
                billingGroup: {
                  id: i.billingGroup.id,
                  name: i.billingGroup.name,
                },
              }));

            setSelectedExtractionRights(selectedRight);
          }}
        />
      </div>

      <div className="grow" />

      <footer className="flex gap-4 p-6 border-t border-gray-200">
        <button
          type="button"
          className="btn-outline-primary"
          onClick={() => {
            stepHelpers.goToPrevStep();
          }}
        >
          {t("common.prev_step")}
        </button>
        <button
          type="button"
          className="btn-primary"
          onClick={() => {
            if (subscriberLevel0Resource.overusedBalance > 0) {
              setOpenConfirmModal(true);
            } else {
              stepHelpers.goToNextStep();
            }
          }}
          disabled={!selectedExtractionRights?.length}
        >
          {t("common.next_step")}
        </button>
        <button
          type="button"
          className="btn-outline-primary"
          onClick={handleCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
      <ConfirmModal
        open={!isLevel0ResourceLoading && openConfirmModal}
        confirmText={t("common.confirm")}
        onConfirm={stepHelpers.goToNextStep}
        onClose={() => setOpenConfirmModal(false)}
      >
        {t("approval.permanent_trades.modal.confirm.overused_balance_warning", {
          overusedBalance: formatVolume(
            subscriberLevel0Resource.overusedBalance,
          ),
          level0ResourceIdentifier: level0Resource.identifier,
        })}
      </ConfirmModal>
    </>
  );
};

export default SelectExtractionRights;
