import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useStep, UseStepHelpers } from "@hooks/useStep";
import { formatDatetimeInput } from "@utils/formatDate";
import { type ConfirmData } from "@components/shared/ConfirmationDetail";
import { formatAccountingPeriod } from "@utils/formatAccountingPeriod";
import HandleGoBackOrClose from "@components/shared/HandleGoBackOrClose";
import { formatDateTime } from "@utils/formatDateTime";
import { Level0WRSSource } from "@services/level0Resource";

type Details = {
  level1wrs: {
    id: string;
    name: string;
    identifier: string;
    level0WRSMembers: any;
  };
  level0wrs: {
    id: string;
    identifier: string;
    yield: number;
    hasClasses: boolean;
    source: string;
  };
  subscribers: {
    id: string;
    accountNumber: string;
  }[];
  accountingPeriod: { id: string; periodStart: Date; periodEnd: Date };
  startAt: string;
};

type HandleChangeDetails = (
  key: keyof Details,
  value: any,
  subKey?: string,
) => void;

type Info = Record<"wrsHierarchies" | "subscribers" | "details", ConfirmData>;

type ContextValue = {
  currentStep: number;
  stepHelpers: UseStepHelpers;
  details: Details;
  setDetails: React.Dispatch<React.SetStateAction<Details>>;
  handleChangeDetails: HandleChangeDetails;
  workflowCompleted: boolean;
  setWorkflowCompleted: React.Dispatch<React.SetStateAction<boolean>>;
  workflowInstance: any;
  setWorkflowInstance: React.Dispatch<React.SetStateAction<any>>;
  navigateForCancel: () => void;
  networkErrors: string[];
  setNetworkErrors: React.Dispatch<React.SetStateAction<string[]>>;
  info: Info;
};

const CreateSpecialAnnouncementContext = React.createContext<
  ContextValue | undefined
>(undefined);

const initialDetails: Details = {
  level1wrs: { id: "", name: "", identifier: "", level0WRSMembers: undefined },
  level0wrs: {
    id: "",
    identifier: "",
    yield: 0,
    hasClasses: false,
    source: Level0WRSSource.Surface,
  },
  subscribers: [],
  accountingPeriod: { id: "", periodStart: new Date(), periodEnd: new Date() },
  startAt: formatDatetimeInput(new Date()),
};

const CreateSpecialAnnouncementProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { t } = useTranslation();
  const maxStep = 5;
  const [currentStep, stepHelpers] = useStep(maxStep);
  const handleGoBackOrClose = HandleGoBackOrClose();
  const [details, setDetails] = React.useState(initialDetails);
  const [workflowCompleted, setWorkflowCompleted] = React.useState(false);
  const [workflowInstance, setWorkflowInstance] = React.useState<any>();
  const [networkErrors, setNetworkErrors] = React.useState<string[]>([]);
  const [showAll, setShowAll] = useState(false);

  const handleChangeDetails = (key: string, value: any, subKey?: string) => {
    setNetworkErrors([]);
    setDetails((prevState: any) => {
      const updatedDetails: any = { ...prevState };
      if (subKey) {
        updatedDetails[key] = { ...prevState[key], [subKey]: value };
      } else {
        updatedDetails[key] = value;
      }
      return updatedDetails;
    });
  };

  const navigateForCancel = handleGoBackOrClose;

  const info: Info = {
    wrsHierarchies: {
      title: t("common.level1wrs_and_level0wrs"),
      body: [
        {
          key: t("common.level1wrs"),
          value: `${details.level1wrs?.name} (${details.level1wrs?.identifier})`,
        },
        {
          key: t("common.accounting_period"),
          value: formatAccountingPeriod({
            periodStart: details.accountingPeriod?.periodStart.toString(),
            periodEnd: details.accountingPeriod?.periodEnd.toISOString(),
          }),
        },
        {
          key: t("common.level0wrs"),
          value: details.level0wrs?.identifier,
        },
      ],
    },
    subscribers: {
      title: t("common.subscribers"),
      body: [
        {
          key: t("common.subscribers"),
          value: (
            <div>
              <ol className="text-gray-500 px-4" aria-label="Subscribers List">
                {details?.subscribers
                  ?.slice(0, showAll ? details?.subscribers.length : 5)
                  .map((r: any, index: any) => {
                    return (
                      <li key={index} className="mb-1 font-medium">
                        {index + 1}. {r?.name}
                      </li>
                    );
                  })}
              </ol>
              {details?.subscribers?.length > 5 && (
                <div className="mt-1 text-sm text-blue-900">
                  <button
                    onClick={() => setShowAll(!showAll)}
                    aria-expanded={showAll}
                  >
                    {showAll ? t("common.see_less") : t("common.see_more")}
                  </button>
                </div>
              )}
            </div>
          ),
        },
      ],
    },
    details: {
      title: t("special_announcement.details"),
      body: [
        {
          key: t("special_announcement.start_at"),
          value: details.startAt
            ? formatDateTime(new Date(details.startAt))
            : "",
        },
      ],
    },
  };
  const value: ContextValue = {
    currentStep,
    stepHelpers,
    details,
    setDetails,
    handleChangeDetails,
    workflowCompleted,
    setWorkflowCompleted,
    workflowInstance,
    setWorkflowInstance,
    navigateForCancel,
    networkErrors,
    setNetworkErrors,
    info,
  };

  return (
    <CreateSpecialAnnouncementContext.Provider
      value={value}
      children={children}
    ></CreateSpecialAnnouncementContext.Provider>
  );
};

const useCreateSpecialAnnouncementContext = () => {
  const context = React.useContext(CreateSpecialAnnouncementContext);
  if (context === undefined) {
    throw new Error(
      "useCreateSpecialAnnouncementContext must be used within a CreateSpecialAnnouncementProvider",
    );
  }
  return context;
};

export {
  CreateSpecialAnnouncementProvider,
  useCreateSpecialAnnouncementContext,
};
